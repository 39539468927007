import { motion } from "framer-motion";
import React from "react";
import { useInView } from "react-intersection-observer";
import { fadeIn } from "../variants";

const About = () => {
  const [ref, InView] = useInView({
    threshold: 0.5,
  });
  return (
    <section className="min-h-[85vh] lg:min-h-[78vh] flex items-center mt-10" id="about" ref={ref}>
      <div className="container mx-auto text-black my-auto mt-10 px-4 sm:px-6">
        <div className="flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0">
          {/* img */}
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 bg-about bg-contain bg-no-repeat min-h-[400px] lg:min-h-[600px] bg-center relative"
          ></motion.div>

          {/*text */}
          <motion.div
            variants={fadeIn("left", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 mb-10 lg:mb-0"
          >
            <h2 className="font-primary text-[48px] lg:text-[72px] font-semibold text-emerald-600">
              About me
            </h2>
            <p className="mb-10 pt-10 text-[16px] lg:text-[28px] text-left">
              Computer Science major with hands-on experience in full-stack development, leveraging a diverse tech stack that includes Java, JavaScript, and Go. I've successfully designed and deployed projects using frameworks such as Spring, Flask, and React. I thrive in collaborative environments and am eager to contribute my technical expertise to drive innovative solutions and support team success.
            </p>
            {/* stats
            <div className="flex gap-x-10 lg:gap-x-12 mb-12">
              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-2">
                  {InView ? <CountUp start={0} end={2} duration={4} /> : null}
                </div>
                <div className="font-primary text-sm tracking-[3px]">
                  Years of <br />
                  Experience
                </div>
              </div>

              <div>
                <div className="text-[40px] font-tertiary text-gradient mb-2">
                  {InView ? <CountUp start={0} end={4} duration={4} /> : null}
                </div>
                <div className="font-primary text-sm tracking-[3px]">
                  Projects <br />
                  Completed
                </div>
              </div>
            </div> */}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
