import React from "react";
import Image from "../assets/avatar.svg";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Banner = () => {
  return (
    <section
      className="min-h-[85vh] lg:min-h-[78vh] flex items-center"
      id="home"
    >
      <div className="container mx-auto text-black px-4 sm:px-6">
        <div className="flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12">
          <div className="flex-1 text-center font-secondary lg:text-left lg:ml-6 mt-[-15vh] lg:mt-0">
            <motion.h1
              variants={fadeIn("down", 0.5)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.7 }}
              className="text-[55px] font-bold leading-[0.8] lg:text-[110px]"
            >
              Alex <span>Yan</span>
            </motion.h1>

            <motion.div
              variants={fadeIn("down", 0.4)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.7 }}
              className="mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold leading-[1.4] pt-3 overflow-hidden"
            >
              <TypeAnimation
                sequence={[
                  "Full Stack Developer",
                  2000,
                  "Planespotter",
                  2000,
                  "Photographer",
                  2000,
                  "Keebs Enthusiast",
                  2000,
                  "Formula One Fan",
                  2000,
                ]}
                wrapper="span"
                repeat={Infinity}
                speed={50}
                className="text-emerald-600 block overflow-hidden"
              />
            </motion.div>

            <motion.p
              variants={fadeIn("down", 0.3)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.7 }}
              className="text-2xl font-semibold mb-8 max-w-sm lg:max-w-xl mx-auto lg:mx-0 overflow-hidden"
            >
              M.S. Computer Science @ <a href={"http://www.yale.edu"} className="hover:text-amber-400 text-sky-800 cursor-none">Yale University</a>
              <br />
              Prev-Software Development Intern @ <a href={"https://aws.amazon.com/"} className="hover:text-cyan-500 text-amber-500 cursor-none">Amazon</a>
              <br />
              Prev-Software Development Intern @ <a href={"https://www.baidu.com/"} className="hover:text-red-400 text-blue-500 cursor-none">Baidu</a>
            </motion.p>
            {/* 
            <motion.div
              variants={fadeIn("down", 0.3)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.7 }}
              className="flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0"
            >
              <button className="btn btn-lg">Contact me</button>
              <a href="#" className="text-gradient btn-link">
                My Portfolio
              </a>
            </motion.div> */}

            <motion.div
              variants={fadeIn("down", 0.3)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.7 }}
              className="flex gap-x-6 max-w-max mx-auto lg:mx-0"
            >
              <a href="https://github.com/Alex-YuYan" className="cursor-none mr-6">
                <FaGithub className="text-[48px]" />
              </a>
              <a href="https://www.linkedin.com/in/alexyuyan/" className="cursor-none mr-6">
                <FaLinkedin className="text-[50px]" />
              </a>
            </motion.div>
          </div>

          <motion.div
            variants={fadeIn("up", 0.5)}
            initial="hidden"
            whileInView="show"
            className="hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]"
          >
            <img src={Image} alt="" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
