import React, { useState } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import { SiGmail } from "react-icons/si";
import { AiOutlineMail } from "react-icons/ai";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:a.yan@yale.edu?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(`Name: ${name}\nEmail: ${email}\n\n${message}`)}`;    
    window.location.href = mailtoLink;
  };

  return (
    <div className="min-h-[85vh] lg:min-h-[78vh] flex items-center mt-24 pb-32 lg:pb-40" id="contact">
      <div className="container mx-auto text-black px-4 sm:px-6">
        <motion.div
          variants={fadeIn("down", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.3 }}
          className="flex-1 mt-10"
        >
          <h2 className="font-primary font-semibold text-[48px] text-emerald-600 mb-6">
            Contact Me
          </h2>
          
          <div className="max-w-3xl mx-auto">
            
            <div className="bg-white/30 backdrop-blur-sm rounded-lg p-6 shadow-xl border-2 border-emerald-600/40 mt-16">
              <h3 className="text-xl font-semibold text-emerald-700 mb-4 text-center">Send Me a Message</h3>
              
              <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Your Name</label>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="John Doe"
                      className="w-full p-2 rounded-lg border-2 border-emerald-600/40 focus:outline-none focus:border-emerald-600 text-sm"
                      required
                    />
                  </div>
                  
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Your Email</label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="john@example.com"
                      className="w-full p-2 rounded-lg border-2 border-emerald-600/40 focus:outline-none focus:border-emerald-600 text-sm"
                      required
                    />
                  </div>
                </div>
                
                <div>
                  <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">Subject</label>
                  <input
                    type="text"
                    id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Subject of your message"
                    className="w-full p-2 rounded-lg border-2 border-emerald-600/40 focus:outline-none focus:border-emerald-600 text-sm"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Message</label>
                  <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here..."
                    className="w-full p-3 rounded-lg border-2 border-emerald-600/40 focus:outline-none focus:border-emerald-600 h-[150px] resize-none text-sm"
                    required
                  />
                </div>
                
                <button
                  type="submit"
                  className="bg-emerald-600 text-white py-2 px-5 rounded-lg hover:bg-emerald-700 transition-colors font-medium mt-1"
                >
                  Send Email
                </button>
              </form>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
