import { motion } from "framer-motion";
import { FaAws, FaDocker, FaGit, FaJava, FaJs, FaLinux, FaPython, FaReact } from "react-icons/fa";
import { FaGolang } from "react-icons/fa6";
import { SiHeroku, SiKubernetes, SiMongodb, SiMysql, SiRedis, SiSpringboot, SiTailwindcss } from "react-icons/si";
import { fadeIn } from "../variants";
import Resumate from '../assets/Resumate_Project.png'

const services = [
  {
    name: "Java",
    icon: <FaJava />,
  },
  {
    name: "Python",
    icon: <FaPython />,
  },
  {
    name: "Golang",
    icon: <FaGolang />,
  },
  {
    name: "JavaScript",
    icon: <FaJs />,
  },
  {
    name: "React",
    icon: <FaReact />,
  },
  {
    name: "Spring",
    icon: <SiSpringboot />,
  },
  {
    name: "MongoDB",
    icon: <SiMongodb />,
  },
  {
    name: "Tailwind",
    icon: <SiTailwindcss />
  },
  {
    name: "AWS",
    icon: <FaAws />,
  },
  {
    name: "Heroku",
    icon: <SiHeroku />,
  },
  {
    name: "Docker",
    icon: <FaDocker />,
  },
  {
    name: "K8s",
    icon: <SiKubernetes />,
  },
  {
    name: "Redis",
    icon: <SiRedis />,
  },
  {
    name: "Git",
    icon: <FaGit />,
  },
  {
    name: "SQL",
    icon: <SiMysql />,
  },
  {
    name: "Linux",
    icon: <FaLinux />
  }
];

export default function Services() {
  return (
    <section className="min-h-[85vh] lg:min-h-[78vh] flex items-center mt-10" id="services">
      <div className="container mx-auto text-black mt-10 px-4 sm:px-6">
        <div className="flex flex-col lg:flex-row">
          <motion.div
            variants={fadeIn("right", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 mb-10 lg:mb-0"
          >
            <h2 className="font-primary font-semibold text-4xl text-emerald-600 mb-10">
              Projects
            </h2>
            <div className="flex flex-col space-y-4">
              <div className="relative">
                <img
                  src={Resumate}
                  alt="Project 1"
                  className="w-full max-w-[450px] h-auto aspect-[3/2] object-cover rounded-lg shadow-lg img-selectable"
                  data-blobity-tooltip="Resumate Project"
                  onClick={
                    () => window.open("http://cc-proj-frontend.s3-website-us-east-1.amazonaws.com/")
                  }
                />
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1"
          >
            <h2 className="font-primary text-4xl font-semibold text-emerald-600 mb-10">
              Tech Stack
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 max-w-full overflow-hidden">
              {services.map((service, index) => {
                const { name, icon } = service;
                return (
                  <div
                    className="border-b rounded-full border-black/60 shadow-md shadow-emerald-600 h-[100px] mb-[20px] flex flex-col items-center justify-center px-2"
                    key={index}
                    data-blobity
                    data-blobity-radius="44"
                  >
                    <div className="flex items-center justify-center text-emerald-800 text-[40px]">
                      {icon}
                    </div>
                    <h4 className="text-[16px] tracking-wider font-primary font-semibold text-emerald-800 text-center truncate w-full">
                      {name}
                    </h4>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
